// src/components/ButtonGroup.js
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import germImg from "../../images/germImg.svg"
import germImgWhite from "../../images/germImgWhite.svg"

const QuoteDiv = ({ text1, text2, bgColor }) => {
  return (
    <div className="row lg_mt-2 mt-2 small-collapse medium-uncollapse">
      <div className={`quote-div centered_div ${bgColor}`}>
        <p className="quote_p centered_div">{text1}</p>
        <p className="name_p centered_div">{text2}</p>
        <img
          alt=""
          src={germImg}
          width="100%"
          className="germ_img gray"
          height="auto"
        />

        <img
          alt=""
          src={germImgWhite}
          width="100%"
          className="germ_img white"
          height="auto"
        />
      </div>
    </div>
  )
}

QuoteDiv.propTypes = {
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
}

export default QuoteDiv
