import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import PageHero from "../../components/template-partials/page-hero"
import ReferenceBlock from "../../components/template-partials/reference-block"
import QuoteDiv from "../../components/template-partials/quote-div"

import FooterCrown from "../../components/template-partials/footer-crown"
import FlipCard from "../../components/template-partials/flip-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { AnchorLink } from "gatsby-plugin-anchor-links"

//images
import aboutSmProgrsssBg from "../../images/about-sm-progress-bg.png"

import aboutSmProgress from "../../images/about-sm-progress-desktop.png"
import anaphylaxisCard from "../../images/cards/anaphylaxis-card.png"
import skinLesionsCard from "../../images/cards/skin-lesions-card.png"
import diarrheaCard from "../../images/cards/diarrhea-card.png"

import gastroCard from "../../images/cards/gastro-card.png"
import neuroCard from "../../images/cards/neuro-card.png"
import cardioCard from "../../images/cards/cardio-card.png"
import skinCard from "../../images/cards/skin-card.png"
import musculoCard from "../../images/cards/musculoskeletal-card.png"
import respiratoryCard from "../../images/cards/respiratory-card.png"
import systemicCard from "../../images/cards/systemic-card.png"

import systemic from "../../images/card-systemic.png"
import cardiovascular from "../../images/card-cardio.png"
import musculoskeletal from "../../images/card-musculo.png"
import respiratory from "../../images/card-respiratory.png"
import neuropsychiatric from "../../images/card-neuro.png"

import gi from "../../images/card-gastro.png"
import agChart from "../../images/card-artery.png"
import skinSpots from "../../images/card-skin.png"

const SymptomsPage = () => (
  <Layout route="recognizing-sm/symptoms">
    <Seo
      page="test-kit"
      title="Systemic Mastocytosis (SM) Symptoms"
      description="Review the signs and symptoms of systemic mastocytosis (SM), how symptoms can be triggered,
and how recognizing symptoms can avoid a diagnostic delay."
    />

    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          <span className="powder">
            Unpredictable and potentially severe symptoms
          </span>{" "}
          can occur in patients with systemic mastocytosis (SM)<sup>1-3</sup>
        </h1>

        <p className="h2 bold mt-2 purple header-margin">
          Recognizing the symptoms of SM may{" "}
          <span className="powder">help with earlier identification</span>
        </p>

        <p className="bodyP purple">
          In SM, skin lesions, diarrhea, anaphylaxis, and other symptoms often
          appear with unexplained persistence or recurrence.<sup>1,2</sup>
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-1 lg_mb-2 mb-2 align-center medium-align-left">
      <div className="columns small-12 text-center">
        <p className="color--purple lead mobileBodyP s-mb-4">
          <strong>
            Select each of the cards below to learn about these common symptoms
            of SM
          </strong>
        </p>
      </div>
    </div>

    <div className="row lg_mt-7 mt-4">
      <div className="columns no-padding ">
        <div className="about-cards">
          <div className="row about-cards__card-deck align-justify">
            <div className="columns small-12 large-expand rowSmCard">
              <FlipCard>
                <div className="front">
                  <img
                    alt="Diarrhea symptoms associated with systemic mastocytosis"
                    src={diarrheaCard}
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img
                        alt="Gastrointestinal system"
                        src={gi}
                        width="100%"
                        height="auto"
                        className="sm-card-img"
                      />
                      <p className="font64 text--watch-quinn lg_mt-2 mt-1 mb-0 fw">
                        64%
                      </p>
                      <p className="lead">
                        <strong>
                          of patients experience diarrhea<sup>4</sup>*
                        </strong>
                      </p>
                      <p className="lead text--regular mb-0">
                        Whereas increased presence of gastrointestinal (GI)
                        mucosal mast cells is seen in many common GI disorders,
                        spindle-shaped mast cell aggregates and/or CD25
                        expression are unique to SM.<sup>5</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
            <div className="columns small-12 large-expand rowSmCard">
              <FlipCard>
                <div className="front">
                  <img
                    alt="Skin lesions associated with systemic mastocytosis"
                    src={skinLesionsCard}
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img
                        alt="SM skin lesions"
                        src={skinSpots}
                        width="100%"
                        height="auto"
                        className="sm-card-img"
                      />
                      <p className="font64 text--watch-quinn lg_mt-2 mt-1 mb-0 fw">
                        95%
                      </p>
                      <p className="lead">
                        <strong>
                          of patients have
                          <br />
                          maculopapular skin lesions
                          <sup>6&dagger;</sup>
                        </strong>
                      </p>
                      <p className="lead text--regular mb-5 sm-2">
                        Small monomorphic lesions often appear on the thighs or
                        trunk of the body.<sup>6</sup>
                      </p>
                      <p className="lead text--regular mb-0">
                        Wheal-and-flare reaction is elicited by stroking lesion
                        with tongue spatula (Darier's sign).
                        <sup>6</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
            <div className="columns small-12 large-expand rowSmCard">
              <FlipCard>
                <div className="front">
                  <img
                    alt="Anaphylaxis symptoms associated with systemic mastocytosis"
                    src={anaphylaxisCard}
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="back">
                  <div className="row align-middle">
                    <div className="columns text-center">
                      <img
                        alt="Anaphylaxis"
                        src={agChart}
                        width="100%"
                        height="auto"
                        className="sm-card-img"
                      />
                      <p className="font64 text--watch-quinn lg_mt-2 mt-1 mb-0 fw">
                        50%
                      </p>
                      <p className="lead">
                        <strong>
                          of patients experience
                          <br /> anaphylaxis
                          <sup>6&dagger;</sup>
                        </strong>
                      </p>
                      <p className="lead text--regular mb-5 sm-2">
                        Commonly triggered by hymenoptera stings (such as bees
                        or wasps) or can be idiopathic.
                        <sup>3</sup>
                      </p>
                      <p className="lead text--regular mb-0">
                        Anaphylaxis is often coupled with hypotension and can be
                        life-threatening.<sup>3</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </FlipCard>
            </div>
          </div>

          <div className="row about-cards__cta align-center lg_mt-2 mt-2 about_cards_row">
            <div className="columns large-12 text-center">
              <ul className="hanging-ref color--white footnote_ul_14">
                <li>
                  <span className="ref">*</span>
                  Data from a validation study of 2 patient-reported outcome
                  measures of quality of life and symptom burden in 164 patients
                  with indolent systemic mastocytosis (ISM).<sup>4</sup>
                </li>
                <li>
                  <sup className="ref">†</sup>
                  As described by an expert-panel review of adult-onset
                  mastocytosis (predominantly indolent population).<sup>6</sup>
                </li>
              </ul>
              <br />

              <p className="lead bodyP">
                Other common symptoms include brain fog, headache, dizziness,
                abdominal pain, nausea, itching, flushing, bone pain, and
                fatigue. These are not all the possible symptoms patients with
                SM may experience.<sup>1,2</sup>
              </p>

              <p className="h2 mt-3 mb-2 about-cards-signoff">
                <strong>Are your patients exhibiting symptoms of SM?</strong>
              </p>
              <p className="lead bodyP about-cards-subtitle">
                Consider initiating testing for serum tryptase and KIT D816V at
                the first sign of the disease.<sup>2,7</sup>
              </p>

              <AnchorLink
                to="/diagnosing-sm/"
                title="Learn More"
                className="btn btn--mustard btn--small-fw lg_mt-1"
              >
                LEARN MORE <FontAwesomeIcon className="" icon={faAngleRight} />
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-5 mt-1 align-center medium-align-left">
      <div className="columns small-12 medium-expand">
        <p className="h2 bold mt-2 purple">
          Symptoms of SM can be{" "}
          <span className="powder">triggered by aspects of everyday life</span>,
          including heat, stress, exercise, and food<sup>2,5</sup>
        </p>
      </div>
    </div>

    <div className="mobile-padding">
      <QuoteDiv
        text1="“I am filled with fear about eating. I am filled with fear about going to sleep. I am filled with fear about being alone.”"
        text2="Kristine, a patient describing the unpredictability of&nbsp;ISM symptom triggers"
        bgColor="purple"
      />
    </div>

    <div className="row lg_mt-3 mt-3 align-center medium-align-left">
      <div className="columns small-12 medium-expand">
        <p className="h2 bold mt-2 purple ">
          Symptoms of SM can manifest across multiple organ systems, including
          <span className="powder"> skin</span>,{" "}
          <span className="powder">gastrointestinal</span>,{" "}
          <span className="powder">neurocognitive</span>, and others
          <sup>1,3</sup>
        </p>

        <p className="color--purple h5 mt-3 mt-2 purple tightened-line-height">
          Patients with SM may present with 1 or more signs and symptoms across
          organ systems. Explore the range of signs and symptoms across multiple
          organ systems for patients living with SM.<sup>1,3</sup>
        </p>
        <p className="color--purple h5 mt-3 s-mb-2 text-center purple">
          <strong>
            Select each of the cards below to learn more about the effects of SM
            on <br className="show-for-large" />
            each organ and the resulting symptoms
          </strong>
        </p>
      </div>
    </div>

    <div className="row lg_mt-1 mt-3 align-center row-cards-organs">
      <div
        className="columns small-12 large-offset-0 large-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Systemic SM symptoms"
              src={systemicCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Human anatomy"
                  src={systemic}
                  width="100%"
                  height="auto"
                  className="sm-card-organs-img"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    SYSTEMIC<sup>1</sup>
                  </strong>
                </p>
                <ul>
                  <li>Fatigue</li>
                  <li>Weight loss</li>
                  <li>
                    Anaphylaxis with
                    <br /> <span>hypotension</span>
                  </li>
                  <li>Malaise</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div
        className="columns small-12 large-offset-0 large-3 mt-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Respiratory symptoms associated with systemic mastocytosis"
              src={respiratoryCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Lungs"
                  className="sm-card-organs-img"
                  src={respiratory}
                  width="100%"
                  height="auto"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    RESPIRATORY<sup>2</sup>
                  </strong>
                </p>
                <ul>
                  <li>Dyspnea</li>
                  <li>Nasal congestion</li>
                  <li>Throat swelling</li>
                  <li>Wheezing</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>

      <div
        className="columns small-12 large-offset-0 large-3 mt-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Musculoskeletal symptoms associated with systemic mastocytosis"
              src={musculoCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Human musculoskeletal anatomy"
                  src={musculoskeletal}
                  width="100%"
                  height="auto"
                  className="sm-card-organs-img"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    MUSCULOSKELETAL<sup>1</sup>
                  </strong>
                </p>
                <ul>
                  <li>Bone pain</li>
                  <li>Myalgia</li>
                  <li>
                    Osteoporosis,
                    <br />
                    <span>
                      osteopenia, and osteolytic
                      <br /> lesions
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div
        className="columns small-12 large-offset-0 large-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Skin lesions associated with systemic mastocytosis"
              src={skinCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="SM skin lesions"
                  src={skinSpots}
                  className="sm-card-organs-img"
                  width="100%"
                  height="auto"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    SKIN<sup>2</sup>
                  </strong>
                </p>
                <ul>
                  <li>Pruritus</li>
                  <li>Flushing</li>
                  <li>Maculopapular lesions</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
    </div>

    <div className="row align-center lg_mt-2 mt-3 row-cards-organs">
      <div
        className="columns small-12 large-offset-0 large-3 mt-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Cardiovascular symptoms associated with systemic mastocytosis"
              src={cardioCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Heart"
                  src={cardiovascular}
                  className="sm-card-organs-img"
                  width="100%"
                  height="auto"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    CARDIOVASCULAR<sup>1</sup>
                  </strong>
                </p>
                <ul>
                  <li>Hypotension</li>
                  <li>Dizziness</li>
                  <li>Palpitations</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div
        className="columns small-12 large-offset-0 large-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Neuropsychiatric symptoms associated with systemic mastocytosis"
              src={neuroCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Brain"
                  src={neuropsychiatric}
                  className="sm-card-organs-img"
                  width="100%"
                  height="auto"
                />
                <p className="h4 lg_mt-2 mt-2 mb-0 fw">
                  <strong>
                    NEUROPSYCHIATRIC<sup>2</sup>
                  </strong>
                </p>
                <ul>
                  <li>Memory impairment</li>
                  <li>Brain fog</li>
                  <li>Anxiety</li>
                  <li>Migraines</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div
        className="columns small-12 large-offset-0 large-3 mt-3"
        style={{ maxWidth: `360px` }}
      >
        <FlipCard>
          <div className="front">
            <img
              alt="Gastrointestinal symptoms associated with systemic mastocytosis"
              src={gastroCard}
              width="100%"
              height="auto"
            />
          </div>
          <div className="back back--secondary">
            <div className="row">
              <div className="columns text-center">
                <img
                  alt="Gastrointestinal system"
                  src={gi}
                  className="sm-card-organs-img"
                  width="100%"
                  height="auto"
                />
                <p className="h4 lg_mt-2 mt-2 fw mb-0">
                  <strong>
                    GASTROINTESTINAL<sup>1</sup>
                  </strong>
                </p>
                <ul>
                  <li>Nausea/vomiting</li>
                  <li>Diarrhea</li>
                  <li>Abdominal pain</li>
                  <li>Heartburn or reflux</li>
                </ul>
              </div>
            </div>
          </div>
        </FlipCard>
      </div>
      <div className="columns small-12 large-12 lg_mt-1 mt-2 about_cards_row">
        <p
          className="mb-0 color--purple card_note s-mt-2"
          style={{ lineHeight: `1` }}
        >
          Not an exhaustive list of all symptoms.
        </p>
        <p className="mb-0 color--purple card_note">
          Symptoms may vary from person to person.
        </p>
      </div>
    </div>

    <div className="row lg_mt-2 mt-0 medium-uncollapse">
      <div className="columns">
        <div
          className="hero-breaker hero-breaker--progress breaker_relative"
          style={{ "--bg-image": `url(${aboutSmProgrsssBg})` }}
        >
          {/* <p className="corner_footnote">NC=Non-clonal.</p> */}
          <div className="row align-middle small-col-reverse">
            <div
              className="columns hero-breaker--person hero-breaker--person--left hero-breaker--person--progress small-12 large-5 hero-breaker--person--progress--symptoms"
              style={{ "--bg-image": `url(${aboutSmProgress})` }}
              title="SM symptoms"
            >
              <span className="visually-hidden">Woman-with-head-in-hands</span>
            </div>
            <div className="columns small-12 large-7 text-left lg_mt-2 mt-1">
              <div className="row">
                <div className="columns small-12 large-11 ">
                  <p className="color--purple text--watch-quinn h1 mt-1">
                    Did you know? Symptoms of SM can be perceived as various
                    other disorders and conditions,{" "}
                    <span className="powder">leading to diagnostic delays</span>
                    <sup>2,8</sup>
                  </p>
                  <p className="color--purple bodyP lesionBodyP lead">
                    This includes adult-onset mastocytosis in skin/cutaneous
                    mastocytosis (MIS/CM), irritable bowel syndrome (IBS), and
                    nonclonal mast cell activation syndrome (nc-MCAS).
                    <sup>2,9,10</sup>
                  </p>
                  <Link
                    to="/recognizing-sm/related-conditions/"
                    className="btn btn--mustard btn--small-fw lg_mt-1 mt-1"
                  >
                    Explore Related Conditions{" "}
                    <FontAwesomeIcon className="" icon={faAngleRight} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row lg_mt-7 mt-7 lg_mb-7 mb-7 align-center medium-align-left bottom_nav_button">
      <div className="columns small-12 medium-expand text-right">
        <p className="h4 color--purple mobileBodyP">
          Uncover the potential impact of SM beyond the
          <br className="show-for-large" /> symptoms
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1"
          to="/recognizing-sm/living-with-sm/"
        >
          Living with SM <FontAwesomeIcon className="" icon={faAngleRight} />
        </Link>
      </div>
    </div>

    <FooterCrown type="home" />

    <ReferenceBlock>
      <ol className="color--purple">
        <li>
          Pardanani A. <em>Am J Hematol</em>. 2023;98(7):1097-1116.
        </li>
        <li>
          Theoharides TC et al. <em>N Engl J Med</em>. 2015;373(2):163-172.
        </li>
        <li>
          Gülen T et al. <em>J Intern Med</em>. 2016;279(3):211-228.
        </li>
        <li>
          van Anrooij B et al. <em>Allergy</em>. 2016;71(11):1585-1593.
        </li>
        <li>
          Hahn HP, Hornick JL. <em>Am J Surg Pathol</em>. 2007;31(11):1669-1676.
        </li>
        <li>
          Hartmann K et al. <em>J Allergy Clin Immunol</em>. 2016;137(1):35-45.
        </li>
        <li>
          WHO Classification of Tumours Editorial Board. Haematolymphoid tumours
          [Internet]. Lyon (France): International Agency for Research on
          Cancer; 2024 [cited April 24, 2024]. (
          <em>WHO Classification of Tumours Series</em>, 5th ed.; vol. 11).
          Available from: https://tumourclassification.iarc.who.int/chapters/63
        </li>

        <li>
          Mesa RA et al. <em>Cancer</em>. 2022;128(20):3691-3699.
        </li>
        <li>
          Berezowska S et al.<em> Mod Pathol</em>. 2014;27(1):19-29.
        </li>
        <li>
          Fuchs D et al. <em>J Allergy Clin Immunol Pract</em>.
          2021;9(4):1705-1712.e4.
        </li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default SymptomsPage
