import React, {useState} from "react";

import flipIcon from "../../images/flip-icon.svg";

const FlipCard =({children})=>{

  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip =(e)=>{
    setIsFlipped(!isFlipped);
    e.preventDefault();
  }

  return (
    <>
      <div className={isFlipped ? `flip-card isFlipped` : `flip-card`}>
        <button onClick={(e)=>toggleFlip(e)}><span className="visually-hidden">Click to flip card</span><img src={flipIcon}/></button>
        <div className="content" onClick={(e)=>toggleFlip(e)}>
          {/* Children */}
            {children}
          {/* Children */}
        </div>
      </div>
    </>
  )

}

export default FlipCard;
